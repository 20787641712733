<template>
    <b-card :title="`${$t('create_delivery')}`">
        <form @submit.prevent="createDelivery" @keydown="form.onKeydown($event)" class="edit-form" enctype="multipart/form-data">
            <b-row>
                <b-col sm="6">
                    <div class="form-group">
                        <label for="name">{{ $t('name') }}<span class="text-danger">*</span></label>
                        <input type="text" name="name" class="form-control" v-model="form.name" :class="{ 'is-invalid': form.errors.has('name') }">
                        <has-error :form="form" field="name" />
                    </div>
                    <div class="form-group">
                        <label for="description">{{ $t('description') }}<span class="text-danger">*</span></label>
                        <textarea class="form-control" v-model="form.description" :class="{ 'is-invalid': form.errors.has('description') }"></textarea>
                        <has-error :form="form" field="description" />
                    </div>
                    <div class="form-group">
                        <label for="status">{{ $t('status') }}<span class="text-danger">*</span></label>
                        <select v-model="form.status" class="form-control">
                            <option value="1">{{ $t('enabled') }}</option>
                            <option value="0">{{ $t('disabled') }}</option>
                        </select>
                        <has-error :form="form" field="status" />
                    </div>
                    <div class="form-group">
                        <label for="api_key">{{ $t('api_key') }} (розділяти ,)</label>
                        <textarea class="form-control" v-model="form.api_keys" :class="{ 'is-invalid': form.errors.has('api_keys') }"></textarea>
                        <has-error :form="form" field="api_keys" />
                    </div>
                    <div class="form-group">
                        <label for="default_key">{{ $t('default_key') }}<span class="text-danger">*</span></label>
                        <select v-model="form.default_key" class="form-control">
                            <option v-for="(k, index) in apiKeys" :value="k" :key="index">{{ k }}</option>
                        </select>
                        <has-error :form="form" field="default_key" />
                    </div>
                    <div class="form-group">
                        <label for="city">{{ $t('city_sender') }}</label>
                        <model-list-select
                            :list="cities"
                            option-value="ref"
                            option-text="name"
                            v-model="form.city_ref"
                            :placeholder="$t('nothing_selected')"
                            @searchchange="searchCity"></model-list-select>
                    </div>
                    <div class="form-group">
                        <label for="warehouse">{{ $t('warehouse_or_street') }}</label>
                        <model-list-select
                            :list="warehouses"
                            option-value="Ref"
                            option-text="Description"
                            v-model="form.warehouse_ref"
                            :placeholder="$t('nothing_selected')"
                            @searchchange="searchWarehouse"></model-list-select>
                            <small class="text-muted">{{ form.warehouse_ref }}</small>
                    </div>
                </b-col>
                <b-col sm="6"></b-col>
            </b-row>
            <button type="submit" class="btn btn-success" :disabled="form.busy">{{ $t('add') }}</button>
        </form>
    </b-card>
</template>
<script>
import Form from 'vform'
import axios from 'axios'
import { ModelListSelect } from 'vue-search-select'
export default {
    middleware: 'auth',
    layout: 'default',
    metaInfo () {
        return { title: this.$t('create_delivery') }
    },
    components: {
        ModelListSelect
    },
    data: () => ({
        form: new Form({
            name: '',
            description: '',
            status: '',
            api_keys: '',
            defaul_key: '',
            city_ref: '',
            warehouse_ref: ''
        }),
        selectedCity: '',
        cities: [],
        warehouses: []
    }),
    computed: {
        // eslint-disable-next-line vue/return-in-computed-property
        apiKeys () {
            if (this.form.api_keys) {
                return this.form.api_keys.split(',')
            }
        }
    },
    methods: {
        async createDelivery () {
            this.form.busy = true
            const apiUrl = process.env.VUE_APP_ENDPOINT_V1
            await this.form.post(apiUrl + 'deliveries').then(resp => {
                this.$router.push({ name: 'deliveries' })
            }).catch(error => {
                this.form.errors.set(error.response.data.error.errors)
            })
        },
        async searchCity (value) {
            if (value.length > 1) {
                this.searchCityText = value
                const apiUrl = process.env.VUE_APP_ENDPOINT_V1
                await axios.post(apiUrl + 'city/search', { key: value }).then(resp => {
                    this.cities = resp.data.data
                    this.warehouses = []
                })
            }
        },
        async searchWarehouse (value) {
            this.$root.$refs.loading.start()
            if (this.form.city_ref) {
                if (value.length > 0) {
                    const apiUrl = process.env.VUE_APP_ENDPOINT_V1
                    const { data } = await axios.post(apiUrl + 'city/warehouse/cityref', { city: this.form.city_ref, key: value })
                    this.warehouses = data.data
                }
            }
            this.$root.$refs.loading.finish()
        }
    }
}
</script>
